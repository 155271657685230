* {
	font-family: "Noto Serif KR", serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-color: #0262f4;
	#display {
		width: 100%;
		// height: 100vh;
		// height: calc(var(--vh, 1vh) * 100);
		display: grid;
		place-items: center;
	}
	main {
		position: relative;
		// Applies to all subelements
		width: 100%;
		height: 100%;
		background-color: white;
		padding: 20px;
		text-align: center;
		display: grid;

		place-items: center;
	}

	#intro {
		#title {
			font-size: 1.7em;
			text-align: center;
			width: fit-content;
			margin: 0 auto;
		}

		#subtitle {
			font-size: 0.7em;
			.warning {
				color: red;
				font-size: 1em;
			}
		}
	}

	#title {
		font-size: 1.2em;
	}

	#subtitle {
		font-size: 0.7em;
		margin-top: 12px;
	}

	.warning {
		width: 100%;
		// margin: 0 auto;
		display: flex;
		position: absolute;
		// margin: 0 auto;
		width: fit-content;
		text-align: center;
		justify-content: space-between;
		align-items: center;
		// background-color: rgb(230, 185, 185);
		color: red;
		font-size: 14px;
		border-radius: 4px;
		padding: 12px 0;
	}

	#name {
		.warning {
			top: 30%;
		}
		#name-input-wrapper {
			display: grid;
			grid-template-columns: auto 1fr;
			column-gap: 16px;
			input {
				border: none;
				border-bottom: 1px solid black;
				width: 100%;
			}
		}
	}

	#selection,
	#selection-happy {
		display: grid;
		.warning {
			top: 18%;
		}
		#selectors-wrapper {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 12px;
			.checkbox {
				.check {
					background-color: rgb(206, 206, 206);
					width: 20px;
					height: 20px;
				}

				.checked {
					background-color: #0262f4;
					border: 4px rgb(206, 206, 206) solid;
				}
				display: grid;
				grid-template-columns: 20px auto;
			}
		}

		#next-wrapper {
			.selector-stage {
				font-size: 0.8em;
			}
		}
	}

	#selection-happy {
		#selectors-wrapper {
			width: 50%;
			margin: 0 auto;
			grid-template-columns: 1fr;
		}
	}

	#ending {
		grid-template-rows: auto 30% auto;
		row-gap: 32px;
		// padding: 28px 40px;
		padding: 28px;
		#color {
			width: 100%;
			height: 100%;
			margin: 0 16px;
		}

		#title {
			align-self: end;
			margin-bottom: 16px;
		}
		#recipe {
			align-self: start;
			text-align: left;
			.recipe-title {
				font-weight: 700;
				margin-bottom: 16px;
			}

			.recipe-item {
				word-break: keep-all;
				font-size: 0.9em;
			}
		}

		#loop {
			width: 100%;
			height: 100%;
		}
	}

	#ending-load {
		#loading:after {
			content: ". ";
			animation: dots 1.2s steps(5, end) infinite;
		}
	}
}

@media screen and (min-width: 400px) {
	body {
		main {
			position: relative;
			width: 360px;
			height: 640px !important;
			border: 0.5px solid black;
		}
	}
}

@keyframes dots {
	0%,
	20% {
		color: rgba(0, 0, 0, 0);
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	40% {
		color: black;
		text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	60% {
		text-shadow: 0.25em 0 0 black, 0.5em 0 0 rgba(0, 0, 0, 0);
	}
	80%,
	100% {
		text-shadow: 0.25em 0 0 black, 0.5em 0 0 black;
	}
}

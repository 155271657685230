button {
	background: none;
	border: none;
	cursor: pointer;

	font-size: 1.1em;
	&:hover {
		text-decoration: underline;
	}
	width: fit-content;
	height: fit-content;
}
